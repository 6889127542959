
.sidebar{
    overflow: auto;
    top: 0;
    left: 0;
    transition: left 0.4s;
    overflow: auto;
    width: 280px;
}

.sidebar-btn{
    border: none;
    display: none;
    background-color: inherit;
}

.sidebar.show{
    left: 0;
}
.sidebar-link{
    color: rgb(123, 123, 123);
    padding: 6px 20px;
    border-radius: 7px;
    margin-bottom: 2px;
    transition: background-color 0.2s , color 0.2s;
}
.sidebar-link:hover{
    background-color: #e7552f;
    color: #fff;
    padding: 6px 20px;
}
@media (max-width: 1000px){
    .sidebar{
        position: absolute;
        left: -280px;
        overflow: auto;
    }
    main{
        margin-left: 0px;
    }
    .sidebar-btn{
        display: block;
    }
}