tr,
td,
th {
  padding: 1rem;
}

tr {
  padding-block: 1.25rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding-block: 0.875rem;
}

td {
  max-height: 20vh;
}
