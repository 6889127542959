.box {
  margin: 20px 0;
}
.box > .paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.box > .paginate > .previous,
.box > .paginate .next {
  width: 38px;
  height: 42px;
  display: flex;
  margin-inline: 15px;
  cursor: pointer;
}
.box > .paginate > .previous > *,
.box > .paginate .next > * {
  margin: auto;
}

.box > .paginate > .selected {
  background-color: #7b8189;
  color: white;
  border: 1.5px solid #f2f2f2 !important;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.box > .paginate > .page-item,
.box > .paginate .break {
  width: 38px;
  height: 42px;
  display: flex;
  cursor: pointer;
  border: 1.5px solid #f9f9f9;
}

.box > .paginate > .page-item > a,
.box > .paginate .break > a {
  padding: 23% 25%;
  margin: auto;
  font-size: 18px;
}
