*{
    color: #3e3e3e;
    font-weight: 500;
    font-size: 17px;
}
.main{
    margin-left: 280px;
    min-height: 100vh;
    background-color: #f5f5f9;
}

@media (max-width: 1000px){
    .main{
        margin-left: 0px;
    }
}
