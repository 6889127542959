@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

* {
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

div > .swiper > .swiper-pagination > .swiper-pagination-bullet {
  background-color: white;
}
